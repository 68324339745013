<template>
  <ZCard class="owner-signup-card rv-calculator-card">
    <div v-if="!isLoggedIn">
      <p class="form-header">
        <strong>{{ t('calculatorTitle') }}</strong>
      </p>
      <FormRvCalculator @rv-calculator-submitted="submitCalculator" />
    </div>
    <div v-else>
      <p class="form-header">
        <strong>{{ t('formTitle') }}</strong>
      </p>
      <ZButton
        block
        :to="localePath('dashboard-rvs-new')"
      >
        {{ $t('pageLink.listAnRV') }}
      </ZButton>
    </div>
  </ZCard>
</template>

<script setup lang="ts">
import { AUTHENTICATION_INTENT } from '~/constants/authentication'

const { t } = useI18n({
  useScope: 'local',
})
const localePath = useLocalePath()
const { isLoggedIn } = useAuthentication()

function submitCalculator() {
  const { showSignup } = useSignup()
  showSignup({ intent: AUTHENTICATION_INTENT.ListingIntent })
}
</script>

<style lang="scss" scoped>
.form-header {
  font-size: 1.5rem;
}

.rv-calculator-card {
  padding: 0.9rem;
}
</style>

<i18n lang="json">
{
  "en": {
    "calculatorTitle": "How much could you make renting your RV?",
    "formTitle": "List your RV for free today"
  },
  "fr": {
    "calculatorTitle": "Combien pourriez-vous générer en revenu avec votre VR?",
    "formTitle": "Affichez votre VR aujourd'hui"
  }
}
</i18n>
