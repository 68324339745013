<template>
  <ZContainer
    class="faq-accordion"
    itemscope
    itemtype="https://schema.org/FAQPage"
  >
    <h2>
      {{ heading }}
    </h2>
    <ZGrid>
      <ZGridItem
        v-for="(question, index) in questions"
        :key="index"
        lg="4"
        class="my-3"
        itemscope
        itemprop="mainEntity"
        itemtype="https://schema.org/Question"
      >
        <h3
          class="question"
          itemprop="name"
        >
          {{ question.question }}
        </h3>
        <div
          itemscope
          itemprop="acceptedAnswer"
          itemtype="https://schema.org/Answer"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="answer"
            itemprop="text"
            v-html="geoSpecificAnswer(question)"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </ZGridItem>
    </ZGrid>
  </ZContainer>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },

    questions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const { isInUs } = useGeolocation()

    return {
      isInUs,
    }
  },

  methods: {
    geoSpecificAnswer(question) {
      if (this.isInUs && question.answer_us) {
        return question.answer_us
      }
      return question.answer
    },
  },
}
</script>

<style lang="scss" scoped>
.faq-accordion {
  @include content-section-spacing;

  .question {
    @include title-1;
  }

  .answer {
    color: getColor('primary-300');
  }
}
</style>
