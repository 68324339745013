<template>
  <div
    :class="[{ 'rv-images': rvImages }]"
    class="narrow dropdown-wrapper"
  >
    <ZFormInput
      :id="id"
      ref="dropdown-input"
      v-model.trim="currentText"
      :variant="version === 'v3' ? 'primary' : 'light'"
      readonly
      required
      :placeholder="placeholderText"
      @focus="displayOptions"
      @blur="onBlur"
    />

    <ZImage
      v-if="rvImages && currentValue"
      :src="`${$config.public.staticAssetsURL}/web-app/rv-type-icons/2021/${currentValue}_darkblue.svg`"
      alt=""
      class="type-icon selected-type"
    />

    <fa
      :icon="['fas', showOptions ? 'chevron-up' : 'chevron-down']"
      @click="toggleOptions"
    />

    <ZListGroup
      v-if="showOptions"
      ref="dropdown"
      class="dropdown"
    >
      <ZListGroupItem
        v-if="searchable"
        class="search-item"
      >
        <ZFormInput
          ref="searchItem"
          v-model="currentSearch"
          type="search"
          :placeholder="$t('actions.search')"
        />
      </ZListGroupItem>
      <ZListGroupItem
        v-for="(option, index) in visibleOptions"
        :key="index"
        button
        :data-testid="option.value"
        :class="{ active: option.value === currentValue }"
        @mousedown="setValue(option.value, option.text)"
        @mouseup="close"
      >
        <ZImage
          v-if="rvImages"
          :src="`${$config.public.staticAssetsURL}/web-app/rv-type-icons/2021/${option.value}_${
            option.value === currentValue ? 'white' : 'darkblue'
          }.svg`"
          alt=""
          class="type-icon"
        />
        <template v-if="rvImages">
          {{ $t(useRvType(option.value)) }}
        </template>
        <template v-else>
          {{ option.text }}
        </template>
      </ZListGroupItem>
    </ZListGroup>
  </div>
</template>

<script>
import useRvType from '~/lib/useRvType'

export default {
  props: {
    id: {
      type: String,
      default: 'dropdown-input',
    },

    options: {
      type: Array,
      required: true,
    },

    selected: {
      type: [Number, String],
      required: false,
      default: null,
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    rvImages: {
      type: Boolean,
      required: false,
      default: false,
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: [Number, String],
      default: null,
    },

    version: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue', 'change'],

  data() {
    return {
      showOptions: false,
      currentValue: null,
      currentText: null,
      currentSearch: '',
      placeholderText: '',
    }
  },

  computed: {
    visibleOptions() {
      if (this.searchable) {
        return this.options.filter((option) => option.text.toUpperCase().includes(this.currentSearch.toUpperCase()))
      }

      return this.options
    },
  },

  created() {
    this.placeholderText = this.placeholder
    if (!this.placeholder) {
      this.placeholderText = this.options[0].text ? this.options[0].text : ''
    }

    if (this.selected) {
      this.setValue(this.selected, this.selected)
    }

    if (this.modelValue) {
      const foundValue = this.visibleOptions.find((option) => option.value === this.modelValue)
      if (foundValue) {
        this.currentValue = foundValue.value
        this.currentText = foundValue.text
      }
    }
  },

  methods: {
    useRvType,

    onBlur(e) {
      if (e.relatedTarget?.parentElement !== this.$refs.dropdown) {
        this.close()
      }
    },

    displayOptions() {
      this.showOptions = true
    },

    setValue(value, text) {
      this.currentValue = value
      this.currentText = this.rvImages ? this.$t(useRvType(value)) : text
      this.currentSearch = ''
      this.$emit('update:modelValue', value)
      this.$emit('change')
    },

    close() {
      this.showOptions = false
    },

    toggleOptions() {
      if (this.showOptions) {
        this.$refs['dropdown-input'].$el.blur()
      }
      else {
        this.$refs['dropdown-input'].$el.focus()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rv-images {
  .zform-control {
    padding-left: 6rem;
    padding-right: 3rem;
    @include media-min-size(medium) {
      padding-left: 7.5rem;
    }
    &:placeholder-shown {
      padding-left: 1rem;
    }
  }
  .dropdown {
    .zlist-group-item-action {
      padding: 0;
      display: flex;
      justify-content: flex-start;
      flex-shrink: 0;
      align-items: center;
      img {
        margin-right: 1rem;
      }
    }
  }
  .type-icon {
    max-width: 100%;
    width: 5rem;
    padding: 0.625rem;
    @include media-min-size(medium) {
      width: 6.2rem;
    }
    &.selected-type {
      position: absolute;
      top: 0.15rem;
      left: 0;
      @include media-min-size(medium) {
        top: 0rem;
      }
    }
  }
}
.search-item {
  padding: 0.25rem 0.5rem;
}
</style>
