<template>
  <ZForm
    novalidate
    @submit.prevent="onSubmit"
  >
    <div class="mb-3">
      <FormInputDropdown
        id="select-rv"
        v-model="rvType"
        :options="rvTypeOptions"
        :placeholder="t('calculator.selectRv')"
        rv-images
        @change="profitCalculator('rv-type')"
      />
    </div>
    <div class="mb-3">
      <FormInputDropdown
        id="select-year"
        v-model="year"
        :options="yearOptions"
        :placeholder="t('calculator.selectYear')"
        @change="profitCalculator('year')"
      />
    </div>
    <div class="footer-section">
      <h6>{{ t('calculator.subTitle') }}</h6>
      <strong>{{ priceDisplay({ value: profit, showCurrencyCode: false }) }}</strong>
      <span>{{ t('calculator.price') }}</span>
    </div>
    <div class="mt-3">
      <ZButton
        type="submit"
        block
      >
        {{ t('calculator.button') }}
      </ZButton>
    </div>
  </ZForm>
</template>

<script>
import { RVTypeEnumKey } from '~/lib/enums'
import { rvYearOptions } from '~/lib/dateArrayOptions'

export default {
  emits: ['rv-calculator-submitted'],

  setup() {
    const { t, locale } = useI18n({
      useScope: 'local',
    })

    const { priceDisplay } = usePrice()

    return { t, locale, priceDisplay }
  },

  data() {
    return {
      rvType: null,
      year: null,
      profit: 0,
    }
  },

  computed: {
    rvTypeOptions() {
      return [
        { value: RVTypeEnumKey.ClassA, text: 'Class A' },
        { value: RVTypeEnumKey.ClassB, text: 'Class B' },
        { value: RVTypeEnumKey.ClassC, text: 'Class C' },
        { value: RVTypeEnumKey.TravelTrailer, text: 'Travel Trailer' },
        { value: RVTypeEnumKey.FifthWheel, text: 'Fifth Wheel' },
        { value: RVTypeEnumKey.TentTrailer, text: 'Tent Trailer' },
        { value: RVTypeEnumKey.Hybrid, text: 'Hybrid' },
        { value: RVTypeEnumKey.ToyHauler, text: 'Toy Hauler' },
      ]
    },

    yearOptions() {
      const yearsOptions = rvYearOptions(this.rvType)
      return yearsOptions.map((year) => ({
        value: year,
        text: year,
      }))
    },
  },

  created() {
    this.profitCalculator()
  },

  methods: {
    onSubmit() {
      this.$emit('rv-calculator-submitted')
    },

    profitCalculator() {
      if (!this.year || !this.rvType) {
        this.profit = 1400
        return
      }

      const current_year = parseInt(new Date().getFullYear())
      const year_diff = Math.abs(current_year - this.year)

      switch (this.rvType) {
        case RVTypeEnumKey.ClassA:
          this.profit = year_diff > 8 ? 1600 : 1800
          break
        case RVTypeEnumKey.ClassB:
          this.profit = year_diff > 8 ? 1350 : 1500
          break
        case RVTypeEnumKey.ClassC:
          this.profit = year_diff > 8 ? 1400 : 1600
          break
        case RVTypeEnumKey.TravelTrailer:
          this.profit = year_diff > 8 ? 650 : 800
          break
        case RVTypeEnumKey.FifthWheel:
          this.profit = year_diff > 8 ? 800 : 950
          break
        case RVTypeEnumKey.TentTrailer:
          this.profit = year_diff > 8 ? 380 : 550
          break
        case RVTypeEnumKey.Hybrid:
          this.profit = year_diff > 8 ? 550 : 700
          break
        case RVTypeEnumKey.ToyHauler:
          this.profit = year_diff > 8 ? 650 : 800
          break
        default:
          this.profit = 1400
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-section {
  h6 {
    display: block;
  }

  strong {
    font-size: 1.7rem;
    color: getColor('primary-500');
  }

  span {
    margin-left: 7px;
    font-size: 0.95rem;
    color: getColor('primary-300');
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "calculator": {
      "selectRv": "Select RV type",
      "selectYear": "Select year",
      "subTitle": "Renting your RV could make",
      "price": "a week",
      "button": "Get Started"
    }
  },
  "fr": {
    "calculator": {
      "selectRv": "Sélectionnez le type de VR",
      "selectYear": "Sélectionnez l'année du VR",
      "subTitle": "Empochez en moyenne",
      "price": "/par semaine",
      "button": "Commencer"
    }
  }
}
</i18n>
